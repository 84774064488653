import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { backToTop } from "@base/helpers/common";
import { IDistrict } from "@base/modules/administrative/administrative.interface";
import administrativeService from "@base/modules/administrative/administrativeService";
import { ISite, ISitePage, IState } from "@base/modules/site/site.interface";
import siteService from "@base/modules/site/siteService";
import stateService from "@base/modules/state/stateService";
import { baseRoutes } from "@base/routes/baseRoutes";
import themeColor, { initialPage } from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";
import CommonStyles from "@components/CommonStyles";
import FilterBookingHeader from "@components/CommonStyles/Filter/Booking/FilterBookingHeader";
import CardOrderFacility from "@components/OrderFacility/CardOrderFacility";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function OrderFacility() {
  //! state
  const breadcrumItem = [
    {
      href: baseRoutes.home,
      title: <CommonIcons.IconHome />,
    },
    {
      title: "Đặt lịch",
    },
    {
      title: "Khám tại cơ sở y tế",
    },
  ];
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [states, setStates] = useState<IState[]>([]);
  const [districts, setDistricts] = useState<IDistrict[]>([]);

  const [search, setSearch] = useState<string>("");
  const [selectedState, setSelectedState] = useState<string | number | undefined>();
  const [selectedDistrict, setSelectedDistrict] = useState<string | undefined>();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const [sites, setSites] = useState<ISitePage | undefined>();

  const [dropRenderKey, setDropRenderKey] = useState<number>(0);

  //! function
  useEffect(() => {
    initDat();
    backToTop();
  }, []);

  useEffect(() => {
    setSelectedDistrict(undefined);
    setDistricts([]);
    setDropRenderKey(dropRenderKey + 1);

    if (selectedState) {
      const state = states.find((s) => {
        return s.id === selectedState;
      });

      if (state && state.code) {
        getDistricts(state.code);
      }

      return;
    }

    setDistricts([]);
  }, [selectedState]);

  const initDat = async () => {
    getStates();
    // getSites(pageNumber, pageSize, selectedState, selectedDistrict, search);
  };

  const getStates = async () => {
    try {
      const resp = await stateService.getList();
      setStates(resp.data);
    } catch (error) {}
  };

  const getDistricts = async (province_code: string) => {
    try {
      const resp = await administrativeService.getListDistrictByProvinceCode(province_code);
      setDistricts(resp.data);
    } catch (error) {}
  };

  const getSites = async (
    pageNumber: number,
    pageSize: number,
    selectedState: string | number | undefined,
    selectedDistrict: string | undefined,
    search: string,
  ) => {
    try {
      setIsLoading(true);
      const resp = await siteService.getList(pageNumber - 1, pageSize, selectedState, selectedDistrict, undefined, undefined, search);

      setSites(resp.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const onFilter = () => {
    setPageNumber(1);

    requestAnimationFrame(async () => {
      getSites(1, pageSize, selectedState, selectedDistrict, search);
    });
  };

  const onClearFilter = () => {
    setPageNumber(1);
    setSelectedState(undefined);
    setSelectedDistrict(undefined);

    requestAnimationFrame(async () => {
      setDropRenderKey(dropRenderKey + 1);
      getSites(1, pageSize, undefined, undefined, search);
    });
  };

  const onChangePage = (page: number, pageSize: number) => {
    setPageNumber(page);

    requestAnimationFrame(async () => {
      getSites(page, pageSize, selectedState, selectedDistrict, search);
    });
  };
  const searchDebounce = debounce((value) => {
    setSearch(value);
  }, 1000);

  useEffect(() => {
    onFilter();
  }, [selectedState, selectedDistrict, search]);

  //! render
  const renderSite = () => {
    if (isLoading) {
      return <CommonStyles.LoadingUI />;
    }
    return (
      <div className={`grid grid-cols-1 xl:grid-cols-2 gap-7 my-7 ${responseUI.padding}`}>
        {sites?.content_page?.map((site: ISite, idx: number) => {
          return <CardOrderFacility key={idx} site={site} />;
        })}
      </div>
    );
  };
  return (
    <div className="bg-common-white">
      {/* search */}

      <FilterBookingHeader
        title="Đặt lịch khám tại cơ sở y tế"
        subTitle="Hệ thống 50+ Cơ sở y tế uy tín đã được chúng tôi xác minh và kiểm duyệt"
        ComponentFilter={
          <CommonStyles.InputUI
            defaultValue={search}
            placeholder="Nhập nội dung cần tìm kiếm..."
            variant="filled"
            prefix={<CommonIcons.SearchOutlined style={{ color: themeColor.orange }} />}
            onChange={(event) => {
              searchDebounce(event.target.value);
            }}
            onPressEnter={onFilter}
          />
        }
      />

      <div className={`bg-common-background `}>
        <div className={`${responseUI.screen}   py-4`}>
          {/* filter */}
          <div className={`w-full ${responseUI.padding}`}>
            <div key={dropRenderKey} className="flex flex-col md:flex-row gap-4 items-center bg-common-white p-4 rounded-2xl w-full">
              <div className="grow w-full">
                <CommonStyles.SelectUI
                  defaultValue={selectedState}
                  variant="filled"
                  placeholder="Tỉnh/ Thành phố"
                  options={states.map((state: IState) => {
                    return { label: state.name, value: state.id };
                  })}
                  onChange={(value) => setSelectedState(value)}
                  onSearch={() => {
                    console.log("a");
                  }}
                  filterOption={false}
                />
              </div>
              <div className="grow w-full">
                <CommonStyles.SelectUI
                  defaultValue={selectedDistrict}
                  variant="filled"
                  placeholder="Quận/ Huyện"
                  options={districts.map((district: IDistrict) => {
                    return { label: district.name, value: district.code };
                  })}
                  onChange={(value) => setSelectedDistrict(value)}
                />
              </div>

              <div className="w-full md:w-fit flex items-center gap-3">
                <CommonStyles.ButtonUI
                  icon={<CommonIcons.IconRefresh />}
                  className="bg-common-orange100 h-10 lg:h-12 !px-6 hover:!bg-common-orange100"
                  onClick={onClearFilter}
                />
              </div>
            </div>
          </div>

          {sites && sites.content_page && sites.content_page.length > 0 ? (
            <>
              {renderSite()}
              <div className="flex justify-end">
                <CommonStyles.PaginationUI current={pageNumber} pageSize={pageSize} total={sites.total_records} onChange={onChangePage} />
              </div>
            </>
          ) : (
            <div className="flex flex-col justify-center items-center gap-6 mt-16">
              <img src={CommonImages.EmptyRecordList} alt="" />

              <div className="text-common-maintext text-base font-semibold font-['Inter']">{t("noResult")}!</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
