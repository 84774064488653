import { useState } from "react";
import { AxiosError } from "axios";
import accountService from "../accountService";
import { createSearchParams, useNavigate } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";
import authService from "@base/modules/auth/authService";
import { LocalKey } from "@base/constants/LocalKey";
import { useDispatch } from "react-redux";
import { setAuth } from "@base/store/reducers/appCommonSlice";

const useOTP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | any>(null);

  const handleCheckOTP = async (phone_number: string, otp: number, isResetPassword: boolean = false) => {
    try {
      setLoading(true);
      await accountService.checkOTP(phone_number, otp);

      // Check OTP success, do login
      const data = await authService.login(phone_number, String(otp));

      if (data.data) {
        // save data to localstorage
        localStorage.setItem(LocalKey.AUTH, JSON.stringify(data.data));
        // save data to redux
        dispatch(setAuth(data.data));
      }

      navigate({
        pathname: baseRoutes.login,
        search: createSearchParams({
          setupPassword: "true",
          phone: phone_number,
          otp: String(otp),
          reset: isResetPassword ? "true" : "false",
        }).toString(),
      });
    } catch (error: AxiosError | any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetOTP = async (phone_number: string, lang: string) => {
    try {
      setLoading(true);
      await accountService.getOTP(phone_number, lang);
    } catch (error: AxiosError | any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    isLoading,
    error,
    handleCheckOTP,
    handleGetOTP,
  };
};

export default useOTP;
