import Regex from "@base/constants/regex";
import { FormRule } from "antd";
import { useTranslation } from "react-i18next";

const useFormValidation = () => {
  const { t } = useTranslation();
  const minimum = `${t("LoginPage.minimum")}`;
  const maximum = `${t("LoginPage.maximum")}`;
  const characters = `${t("LoginPage.characters")}`;
  const mustNumber = `${t("LoginPage.must number")}`;
  const empty = `${t("Empty")}`;
  const invalidate = `${t("invalidate")}`;
  return {
    login: {
      phone(): FormRule[] {
        const fieldName = `${t("LoginPage.Phonenumber")}`;

        return [
          {
            required: true,
            message: `${fieldName} ${empty}`,
          },
          { pattern: Regex.number, message: `${fieldName} ${mustNumber}` },
          { max: 20, message: `${fieldName} ${maximum} 20 ${characters}` },
        ];
      },

      password(): FormRule[] {
        const fieldName = `${t("LoginPage.Password")}`;
        return [
          {
            required: true,
            message: `${fieldName} ${empty}`,
          },
          { min: 6, message: `${fieldName}  ${minimum} 6 ${characters}` },
          { max: 15, message: `${fieldName}  ${maximum} 15 ${characters}` },
        ];
      },
      confirmPassword(): FormRule[] {
        return [
          {
            required: true,
            message: `${t("LoginPage.Password")} ${empty}`,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("LoginPage.PasswordNotMatch") + "!"));
            },
          }),
        ];
      },
      otp(): FormRule[] {
        return [
          {
            required: true,
            message: `${t("OTPValidationMessage")}!`,
            pattern: new RegExp(/^[0-9]+$/),
          },
        ];
      },
    },

    patientRecord: {
      patientName(): FormRule[] {
        const fieldName = `${t("user.profile.handler.labelName")}`;
        return [
          {
            required: true,
            message: `${fieldName} ${t("Empty")}`,
          },
          { max: 255, message: `${fieldName} ${maximum} 255 ${characters}` },
        ];
      },
      patientDob(): FormRule[] {
        return [
          {
            required: true,
            message: `${t("user.profile.handler.labelDateOfBirth")} ${t("Empty")}`,
          },
        ];
      },
      province(): FormRule[] {
        return [
          {
            required: true,
            message: `${t("user.profile.handler.labelProvince")} ${t("Empty")}`,
          },
        ];
      },
      district(): FormRule[] {
        return [
          {
            required: true,
            message: `${t("user.profile.handler.labelDistrict")} ${t("Empty")}`,
          },
        ];
      },
      address(): FormRule[] {
        return [
          {
            required: true,
            message: `${t("user.profile.handler.labelAddress")} ${t("Empty")}`,
          },
        ];
      },
    },

    accountSetting: {
      email(): FormRule[] {
        const fieldName = `${t("Email")}`;
        return [
          { max: 255, message: `${fieldName}  ${maximum} 255 ${characters}` },
          {
            pattern: Regex.email,
            message: `${fieldName} ${invalidate}!`,
          },
        ];
      },
      display_name(): FormRule[] {
        const fieldName = `${t("user.profile.handler.labelName")}`;
        return [
          {
            required: true,
            message: `${fieldName} ${t("Empty")}`,
          },
          { max: 255, message: `${fieldName} ${maximum} 255 ${characters}` },
        ];
      },
      referral_code(): FormRule[] {
        const fieldName = `${t("user.accountSetting.labelRefCode")}`;
        return [{ max: 255, message: `${fieldName} ${maximum} 255 ${characters}` }];
      },
    },

    appointmentStep1: {
      facility(): FormRule[] {
        return [
          {
            required: true,
            message: `${t("Appointment.facility")} ${t("Empty")}`,
          },
        ];
      },
      service(): FormRule[] {
        return [
          {
            required: true,
            message: `${t("Appointment.service")} ${t("Empty")}`,
          },
        ];
      },
      doctor(): FormRule[] {
        return [
          {
            required: true,
            message: `${t("Appointment.doctor")} ${t("Empty")}`,
          },
        ];
      },
      date(): FormRule[] {
        return [
          {
            required: true,
            message: `${t("Appointment.date")} ${t("Empty")}`,
          },
        ];
      },
      hour(): FormRule[] {
        return [
          {
            required: true,
            message: `${t("Appointment.hour")} ${t("Empty")}`,
          },
        ];
      },
      symptoms(): FormRule[] {
        return [
          {
            required: true,
            message: `${t("Appointment.symptoms")} ${t("Empty")}`,
          },
        ];
      },
    },

    cancelAppt: {
      cancelSelect(): FormRule[] {
        return [
          {
            required: true,
            message: `Lý do hủy ${empty}`,
          },
        ];
      },
      reason(): FormRule[] {
        return [
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue("cancel_select") === "Tôi muốn hủy / lý do khác") {
                if (!value) {
                  return Promise.reject(new Error("Bạn cần nhập ý kiến của mình!"));
                } else {
                  return Promise.resolve();
                }
              }

              return Promise.resolve();
            },
          }),
        ];
      },
    },

    collaboration: {
      type(): FormRule[] {
        return [
          {
            required: true,
            message: `Vai trò cộng tác ${t("Empty")}`,
          },
        ];
      },

      name(): FormRule[] {
        return [
          {
            required: true,
            message: `Tên cơ sở y tế/ cộng tác viên ${t("Empty")}`,
          },
        ];
      },

      name_of_representative(): FormRule[] {
        return [
          {
            required: true,
            message: `Tên người đại diện ${t("Empty")}`,
          },
        ];
      },

      province(): FormRule[] {
        return [
          {
            required: true,
            message: `Tỉnh/Thành phố ${t("Empty")}`,
          },
        ];
      },

      phone_number(): FormRule[] {
        return [
          {
            required: true,
            message: `Số điện thoại ${t("Empty")}`,
          },
        ];
      },

      email(): FormRule[] {
        return [
          {
            required: true,
            message: `Email ${t("Empty")}`,
          },
        ];
      },

      address(): FormRule[] {
        return [
          {
            required: true,
            message: `Địa chỉ kinh doanh ${t("Empty")}`,
          },
        ];
      },

      referral_code(): FormRule[] {
        return [];
      },
    },
  };
};

export default useFormValidation;
