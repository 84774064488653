import React, { useEffect, useState } from "react";
import useGetPosts from "@base/modules/wpApis/hooks/useGetPosts";
import { initialPage } from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import ReactQuill from "react-quill";
import styles from "!!raw-loader!./index.module.css";
import { Post } from "@base/types/wp";
import dayjs from "dayjs";
import CommonIcons from "@base/assets/icons";
import CommonStyles from "@components/CommonStyles";

const StyledReactQuill = styled(ReactQuill)`
  ${styles}
`;

export default function MedicalInfoDetail() {
  //! state
  const { slug } = useParams();
  const { data, error, isLoading, refetch } = useGetPosts(
    { slug: slug ? slug.replace(".html", "") : slug, _embed: true, page: 1, per_page: 1 },
    true,
  );
  const [post, setPost] = useState<Post>();
  const [postContent, setPostContent] = useState<string>("");
  const [author, setAuthor] = useState<any>();
  const [featuredmedia, setFeaturedmedia] = useState<any>();

  //! function
  useEffect(() => {
    if (data?.data.length) {
      const _post = data?.data[0] ?? {};
      setPost(_post);

      setPostContent(_post.content?.rendered ?? "");

      if (_post._embedded && _post._embedded.author && _post._embedded.author.length) {
        setAuthor(_post._embedded.author[0]);
      }

      if (_post._embedded && _post._embedded["wp:featuredmedia"] && _post._embedded["wp:featuredmedia"].length) {
        setFeaturedmedia(_post._embedded["wp:featuredmedia"][0]);
      }
    }
  }, [data]);

  //! render
  return (
    <div className="relative min-h-[300px]">
      <CommonStyles.SpinnerUI isSpinning={isLoading} />

      <StyledReactQuill className={`${!post ? "hidden" : ""}`}>
        <div className="bg-common-white wp-embed-content relative">
          {/* post header */}
          <div className={`${responseUI.screen} ${initialPage.paddingX} page-title blog-featured-title featured-title no-overflow`}>
            <div className="page-title-bg fill">
              <div
                className="title-bg fill bg-fill bg-top parallax-active"
                style={{
                  backgroundImage: `url(${featuredmedia?.source_url ?? ""})`,
                  height: "371.667px",
                  transform: "translate3d(0px, -12.17px, 0px)",
                  backfaceVisibility: "hidden",
                }}
                data-parallax-fade="true"
                data-parallax="-2"
                data-parallax-background=""
                data-parallax-container=".page-title"
              ></div>
              <div className="title-overlay fill" style={{ backgroundColor: "rgba(0,0,0,.5)" }}></div>
            </div>

            <div className="page-title-inner container  flex-row  dark is-large" style={{ minHeight: "300px" }}>
              <div className="flex-col flex-center text-center">
                <h1 className="entry-title" dangerouslySetInnerHTML={{ __html: post?.title?.rendered ?? "" }}></h1>
                <div className="entry-divider is-divider small"></div>

                <div className="entry-meta uppercase is-xsmall">
                  <span className="posted-on">
                    Posted on&nbsp;
                    <time className="entry-date published updated">{dayjs(post?.date).format("DD/MM/YYYY")}</time>
                  </span>
                  &nbsp;
                  <span className="byline">
                    by&nbsp;
                    <span className="meta-author vcard">{author?.name}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* post content */}
          <div className="bg-common-background">
            <div className={`${responseUI.screen} ${initialPage.paddingX} max-[700px]:px-2`}>
              <article id={"post-" + (post?.id ?? "-1")} className={post?.class_list ? post.class_list.join(" ") : ""}>
                <div className="article-inner">
                  <div className="entry-content single-page">
                    <div dangerouslySetInnerHTML={{ __html: postContent }}></div>

                    <div className="blog-share text-center">
                      <div className="is-divider medium"></div>
                      <div className="social-icons share-icons share-row relative">
                        <a
                          href="https://www.facebook.com/sharer.php?u=https://news.365medihome.com.vn/365-medihome-ra-mat-thiet-bi-y-te-thong-minh-techconnect-2024.html"
                          data-label="Facebook"
                          rel="noopener noreferrer nofollow"
                          target="_blank"
                          className="icon button circle is-outline tooltip facebook"
                          title="Chia sẻ lên Facebook"
                          aria-label="Chia sẻ lên Facebook"
                        >
                          <CommonIcons.IconFacebook />
                        </a>
                        <a
                          href="https://twitter.com/share?url=https://news.365medihome.com.vn/365-medihome-ra-mat-thiet-bi-y-te-thong-minh-techconnect-2024.html"
                          rel="noopener noreferrer nofollow"
                          target="_blank"
                          className="icon button circle is-outline tooltip twitter"
                          title="Chia sẻ lên Twitter"
                          aria-label="Chia sẻ lên Twitter"
                        >
                          <CommonIcons.IconX />
                        </a>
                        <a
                          href="mailto:enteryour@addresshere.com?subject=365%20Medihome%20ra%20m%E1%BA%AFt%20Thi%E1%BA%BFt%20b%E1%BB%8B%20y%20t%E1%BA%BF%20th%C3%B4ng%20minh%20t%E1%BA%A1i%20s%E1%BB%B1%20ki%E1%BB%87n%20K%E1%BA%BFt%20n%E1%BB%91i%20c%C3%B4ng%20ngh%E1%BB%87%20v%C3%A0%20%C4%90%E1%BB%95i%20m%E1%BB%9Bi%20s%C3%A1ng%20t%E1%BA%A1o%20Vi%E1%BB%87t%20Nam%20%26%238211%3B%20Techconnect%202024&amp;body=Check%20this%20out:%20https://news.365medihome.com.vn/365-medihome-ra-mat-thiet-bi-y-te-thong-minh-techconnect-2024.html"
                          rel="nofollow"
                          className="icon button circle is-outline tooltip email"
                          title="Gửi Email"
                          aria-label="Gửi Email"
                        >
                          <CommonIcons.IconMail />
                        </a>
                        <a
                          href="https://pinterest.com/pin/create/button/?url=https://news.365medihome.com.vn/365-medihome-ra-mat-thiet-bi-y-te-thong-minh-techconnect-2024.html&amp;media=https://news.365medihome.com.vn/wp-media/2024/10/thiet-bi-y-te-thong-minh-365medihome-techconnect2024-0.jpg&amp;description=365%20Medihome%20ra%20m%E1%BA%AFt%20Thi%E1%BA%BFt%20b%E1%BB%8B%20y%20t%E1%BA%BF%20th%C3%B4ng%20minh%20t%E1%BA%A1i%20s%E1%BB%B1%20ki%E1%BB%87n%20K%E1%BA%BFt%20n%E1%BB%91i%20c%C3%B4ng%20ngh%E1%BB%87%20v%C3%A0%20%C4%90%E1%BB%95i%20m%E1%BB%9Bi%20s%C3%A1ng%20t%E1%BA%A1o%20Vi%E1%BB%87t%20Nam%20%26%238211%3B%20Techconnect%202024"
                          rel="noopener noreferrer nofollow"
                          target="_blank"
                          className="icon button circle is-outline tooltip pinterest"
                          title="Ghim trên Pinterest"
                          aria-label="Ghim trên Pinterest"
                        >
                          <CommonIcons.IconPinterest />
                        </a>
                        <a
                          href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://news.365medihome.com.vn/365-medihome-ra-mat-thiet-bi-y-te-thong-minh-techconnect-2024.html&amp;title=365%20Medihome%20ra%20m%E1%BA%AFt%20Thi%E1%BA%BFt%20b%E1%BB%8B%20y%20t%E1%BA%BF%20th%C3%B4ng%20minh%20t%E1%BA%A1i%20s%E1%BB%B1%20ki%E1%BB%87n%20K%E1%BA%BFt%20n%E1%BB%91i%20c%C3%B4ng%20ngh%E1%BB%87%20v%C3%A0%20%C4%90%E1%BB%95i%20m%E1%BB%9Bi%20s%C3%A1ng%20t%E1%BA%A1o%20Vi%E1%BB%87t%20Nam%20%26%238211%3B%20Techconnect%202024"
                          rel="noopener noreferrer nofollow"
                          target="_blank"
                          className="icon button circle is-outline tooltip linkedin"
                          title="Chia sẻ lên LinkedIn"
                          aria-label="Chia sẻ lên LinkedIn"
                        >
                          <CommonIcons.IconLinkedin />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </StyledReactQuill>
    </div>
  );
}
