import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import useGetCategories from "@base/modules/wpApis/hooks/useGetCategories";
import { baseRoutes } from "@base/routes/baseRoutes";
import { initialPage } from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";
import { PostsCategory } from "@base/types/wp";
import CommonStyles from "@components/CommonStyles";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

interface IProps {
  Header: React.ReactNode;
  Content: React.ReactNode;
  onSelectCategory: (category: PostsCategory) => void;
}

export default function MedicalManualLayout(props: IProps) {
  //! state
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { Header, Content, onSelectCategory } = props;
  const { data, error, isLoading, refetch } = useGetCategories();
  const [categories, setCategories] = useState<PostsCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<PostsCategory>({
    id: searchParams.get("categories") ? Number(searchParams.get("categories")) : -1,
  });

  //! function
  useEffect(() => {
    const _cats: PostsCategory[] = [
      {
        id: -1,
        name: "Tất cả",
        count: 0,
      },
    ];

    if (data && data.data) {
      _cats.push(...data.data);
    }

    setCategories(_cats);
  }, [data]);

  const onSelectItem = (category: PostsCategory) => {
    onSelectCategory(category);
    setSelectedCategory(category);
  };

  const renderCategory = (cats: PostsCategory[]) => {
    return cats.map((item, index) => {
      return (
        <CommonStyles.TypographyUI
          use="paragraph"
          className={`${
            selectedCategory.id === item.id ? "text-common-inprogress" : "text-common-subtext"
          } text-lg font-medium !m-0 cursor-pointer hover:text-[#005984] hover:underline`}
          key={index}
          onClick={() => onSelectItem(item)}
        >
          {item.name}
        </CommonStyles.TypographyUI>
      );
    });
  };

  //! render
  return (
    <div className="bg-common-white">
      <div className={`${responseUI.screen} ${initialPage.paddingX}`}>
        <div className={`lg:mt-10 relative py-5 max-[700px]:px-2`}>
          {Header}
          <div className="absolute right-0 bottom-0">
            <CommonIcons.IconHeart width={150} height={150} />
          </div>
        </div>
      </div>

      <div className="bg-common-background">
        <div className={`${responseUI.screen} ${initialPage.paddingX} grid grid-cols-3 gap-8 py-5 max-lg:grid-cols-1 max-[700px]:px-2`}>
          {/* info */}
          <div className="col-span-2 max-lg:col-span-1">{Content}</div>

          {/* search */}
          <div className="flex flex-col gap-8 p-4 rounded-lg bg-common-white h-fit">
            <div>
              <CommonStyles.TypographyUI use="title" level={3} className={`${responseUI.text.title}`}>
                Danh mục
              </CommonStyles.TypographyUI>
              <div className="flex flex-col gap-2 mt-3">{renderCategory(categories)}</div>
            </div>

            {/* order */}
            <div className="border-common-orange border">
              <div
                className="m-3 px-3 py-7 flex flex-col items-center text-center gap-4"
                style={{
                  backgroundImage: `url(${CommonImages.bgMedicalDownload})`,
                  minHeight: 200,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <img className="w-24" src={CommonImages.logoMedicalDownload} alt={CommonImages.logoMedicalDownload} />
                <CommonStyles.TypographyUI use="paragraph" className="text-common-maintext">
                  Đặt lịch khám - chữa bệnh tại 365 Medihome giúp chủ động thời gian, tiết kiệm chi phí.
                </CommonStyles.TypographyUI>
                <div>
                  <CommonStyles.ButtonUI className="h-10 font-medium" onClick={() => navigate(baseRoutes.orderFacility)}>
                    Đặt lịch ngay
                  </CommonStyles.ButtonUI>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
