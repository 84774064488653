import bgLogin from "./bg-login.png";
import arrowSmRight from "./arrow-sm-right-icon.png";
import ellipseFilled from "./ellipse-filled.png";
import location from "./location-icon.png";
import mail from "./mail-icon.png";
import medihomeApp from "./medihome-app-store.png";
import medihomeCertificates from "./medihome-certificates.png";
import medihomeQr from "./medihome-qr.png";
import medihomeSocialNetwork from "./medihome-social-networks.png";
import pageLayoutLogo from "./page-layout-logo.png";
import phone from "./phone-icon.png";
import DefaultAvatar from "./default-avatar.png";
import medihomePartner from "./medihomePartner.png";
import Doctor from "./Doctor.png";
import Facilities from "./Facilities.png";
import Employee from "./Employee.png";
import PartnerAbout from "./PartnerAbout.png";
import PartAboutDevice from "./PartAboutDevice.png";
import PartnerJoin from "./PartnerJoin.png";
import BGHome from "./bg-home.png";
import BGLeftHome from "./bg-left-home.png";
import EmptyRecordList from "./empty-record-list.png";
import EmptyAppointmentList from "./empty-appointment-list.png";
import TestDoctorAvatar from "./test-doctor-avatar.png";
import BGServiceHome from "./bg-service-home.png";
import ImageConnectHome from "./ImageConnectHome.png";
import PartnerConnect from "./PartnerConnect.png";
import PartnerThuCuc from "./PartnerThuCuc.png";
import BGDownloadApp from "./bg-downloadApp.png";
import MobileAppDownload from "./MobileAppDownload.png";
import googleplayBlack from "./googleplayBlack.png";
import appstoreBlack from "./appstoreBlack.png";
import DrafInforArticle from "./draf/DrafInforArticle.png";
import DrafDoctor from "./draf/DrafDoctor.png";
import DrafPackage from "./draf/DrafPackage.png";
import DrafMedicalDetail from "./draf/DrafMedicalDetail.png";
import bgMedicalDownload from "./medicalInfo/bg-medical-download.png";
import logoMedicalDownload from "./medicalInfo/logo-medical-download.png";
import BGSearch from "./BGSearch.png";
import DrafOrderFacility from "./draf/DrafOrderFacility.png";
import DrafDoctorFacility from "./draf/DrafDoctorFacility.png";
import SiteDefault from "./site-default-image.png";
import imageBooking from "./imageBooking.png";
import ATM from "./payment/ATM.png";
import Banking from "./payment/Banking.png";
import Cash from "./payment/Cash.png";
import Momo from "./payment/Momo.png";
import VNPAY from "./payment/VNPAY.png";
import vnpaysmall from "./payment/vnpaysmall.png";
import VitaPay from "./payment/VitaPay.png";
import PaymentSuccess from "./payment/PaymentSuccess.png";
import imageUploadMedicine from "./imageUploadMedicine.png";
import Giffbox from "./Giffbox.png";
import StepUpload from "./StepUpload.png";
import StepCall from "./StepCall.png";
import StepDelivery from "./StepDelivery.png";
import DefaultDoctorAvatar from "./default-doctor-avatar.png";
import PaymentError from "./payment/PaymentError.png";
import MedihomeAppQR from "./medihome-app-qr.png";
import feedback1 from "./avatarFeedback/feedback1.png";
import feedback2 from "./avatarFeedback/feedback2.png";
import feedback3 from "./avatarFeedback/feedback3.png";
import feedback4 from "./avatarFeedback/feedback4.png";
import feedback5 from "./avatarFeedback/feedback5.png";
import feedback6 from "./avatarFeedback/feedback6.png";
import Order from "./Order.png";
import paymentProtect from "./payment-protect.png";
import default512288 from "./draf/default512-288.png";
import VNLanguage from "./language/VN.png";
import ENLanguage from "./language/ENG.png";
import YT from "./social/YT.png";
import FB from "./social/FB.png";
import GGPlay from "./social/GGPlay.png";
import AppStore from "./social/AppStore.png";
import VNLanguageCircle from "./language/VN_Circle.png";
import ENLanguageCircle from "./language/EN_Circle.png";
import EmptyServicePackageList from "./empty-service-package-list.png";
import drafBG from "./draf/drafBG.png";
import Partner_VNPT from "./PartnerConnect/Partner_VNPT.png";
import Partner_InSMart from "./PartnerConnect/Partner_InSMart.png";
import Partner_Momo from "./PartnerConnect/Partner_Momo.png";
import Partner_OMRON from "./PartnerConnect/Partner_OMRON.png";
import Partner_TLong from "./PartnerConnect/Partner_TLong.png";
import Partner_VNPAY from "./PartnerConnect/Partner_VNPAY.png";
import Partner_Sumitomo from "./PartnerConnect/Partner_Sumitomo.png";
import BannerDefaultSite from "./draf/BannerDefaultSite.png";
import DrugOrder from "./drug-order.png";
import DefaultDoctorAvatarSquare from "./DefaultDoctorAvatarSquare.png";
import ISO27001 from "./certificate/ISO27001.png";
import ISO9001 from "./certificate/ISO9001.png";
import NotifyBCT from "./certificate/NotifyBCT.png";
import RegisterBCT from "./certificate/RegisterBCT.png";
import RejectIncomingCall from "./reject-incoming-call.png";
import ReceiveIncomingCall from "./receive-incoming-call.png";
import BGVideoCall from "./bg-video-call.png";

const CommonImages = {
  bgLogin: bgLogin,
  arrowSmRight: arrowSmRight,
  ellipseFilled: ellipseFilled,
  location: location,
  mail: mail,
  medihomeAppStore: medihomeApp,
  medihomeCertificates: medihomeCertificates,
  medihomeQr: medihomeQr,
  medihomeSocialNetwork: medihomeSocialNetwork,
  phone,
  pageLayoutLogo,
  DefaultAvatar,
  medihomePartner: medihomePartner,
  Doctor: Doctor,
  Facilities: Facilities,
  Employee: Employee,
  PartnerAbout: PartnerAbout,
  PartAboutDevice: PartAboutDevice,
  PartnerJoin: PartnerJoin,
  BGHome: BGHome,
  BGLeftHome: BGLeftHome,
  EmptyRecordList: EmptyRecordList,
  EmptyAppointmentList: EmptyAppointmentList,
  TestDoctorAvatar: TestDoctorAvatar,
  BGServiceHome: BGServiceHome,
  HospitalConnectHome: ImageConnectHome,
  PartnerConnect: PartnerConnect,
  PartnerThuCuc: PartnerThuCuc,
  BGDownloadApp: BGDownloadApp,
  MobileAppDownload: MobileAppDownload,
  googleplayBlack: googleplayBlack,
  appstoreBlack: appstoreBlack,
  DrafInforArticle: DrafInforArticle,
  DrafDoctor: DrafDoctor,
  DrafPackage: DrafPackage,
  bgMedicalDownload: bgMedicalDownload,
  logoMedicalDownload: logoMedicalDownload,
  DrafMedicalDetail: DrafMedicalDetail,
  BGSearch: BGSearch,
  DrafOrderFacility: DrafOrderFacility,
  DrafDoctorFacility: DrafDoctorFacility,
  SiteDefault: SiteDefault,
  imageBooking: imageBooking,
  ATM,
  Banking,
  Cash,
  Momo,
  VNPAY,
  PaymentSuccess: PaymentSuccess,
  imageUploadMedicine: imageUploadMedicine,
  Giffbox: Giffbox,
  StepUpload: StepUpload,
  StepCall: StepCall,
  StepDelivery: StepDelivery,
  DefaultDoctorAvatar: DefaultDoctorAvatar,
  PaymentError: PaymentError,
  MedihomeAppQR: MedihomeAppQR,
  feedback1: feedback1,
  feedback2: feedback2,
  feedback3: feedback3,
  feedback4: feedback4,
  feedback5: feedback5,
  feedback6: feedback6,
  Order: Order,
  paymentProtect: paymentProtect,
  default512288: default512288,
  VNLanguage: VNLanguage,
  ENLanguage: ENLanguage,
  YT: YT,
  FB: FB,
  GGPlay: GGPlay,
  AppStore: AppStore,
  VNLanguageCircle: VNLanguageCircle,
  ENLanguageCircle: ENLanguageCircle,
  EmptyServicePackageList: EmptyServicePackageList,
  drafBG: drafBG,
  Partner_VNPT: Partner_VNPT,
  Partner_InSMart,
  Partner_Momo,
  Partner_OMRON,
  Partner_TLong,
  Partner_VNPAY,
  Partner_Sumitomo,
  BannerDefaultSite: BannerDefaultSite,
  DrugOrder: DrugOrder,
  DefaultDoctorAvatarSquare: DefaultDoctorAvatarSquare,
  ISO27001: ISO27001,
  ISO9001: ISO9001,
  NotifyBCT: NotifyBCT,
  RegisterBCT: RegisterBCT,
  VitaPay: VitaPay,
  vnpaysmall: vnpaysmall,
  RejectIncomingCall: RejectIncomingCall,
  ReceiveIncomingCall: ReceiveIncomingCall,
  BGVideoCall: BGVideoCall,
};

export default CommonImages;
