import IconArrowRight from "./IconArrowRight";
import IconCalendarFill from "./IconCalendarFill";
import IconError from "./IconError";
import IconHealthcare from "./IconHealthcare";
import IconHistory from "./IconHistory";
import IconHome from "./IconHome";
import IconInfo from "./IconInfo";
import IconLocation from "./IconLocation";
import IconLogout from "./IconLogout";
import IconMap from "./IconMap";
import IconMedication from "./IconMedication";
import IconProfile from "./IconProfile";
import IconSuccess from "./IconSuccess";
import IconTextSnippet from "./IconTextSnippet";
import IconVoucher from "./IconVoucher";
import IconWarning from "./IconWarning";
import IconRecord from "./IconRecord";
import { SearchOutlined } from "@ant-design/icons";
import { RightOutlined } from "@ant-design/icons";
import { LeftOutlined } from "@ant-design/icons";
import { ShareAltOutlined } from "@ant-design/icons";
import { HeartOutlined } from "@ant-design/icons";
import PlusHome from "./PlusHome";
import IconRating from "./IconRating";
import IconGraduate from "./IconGraduate";
import IconHospital from "./IconHospital";
import IconSearchHome from "./IconSearchHome";
import IconContractHome from "./IconContractHome";
import IconOrderHome from "./IconOrderHome";
import IconExperienceHome from "./IconExperienceHome";
import IconArrowOrderHome from "./IconArrowOrderHome";
import IconApplication from "./IconApplication";
import IconHeart from "./IconHeart";
import IconBeautyHome from "./IconBeautyHome";
import IconTKHome from "./IconTKHome";
import IconMTHome from "./IconMTHome";
import IconVLTL from "./IconVLTL";
import IconKSK from "./IconKSK";
import IconK31 from "./IconK31";
import IconHomeService from "./IconHomeService";
import IconPhone24h from "./IconPhone24h";
import IconLikeStart from "./IconLikeStart";
import IconCalenderPlus from "./IconCalenderPlus";
import IconPersonEdit from "./IconPersonEdit";
import IconReply from "./IconReply";
import IconDoctor from "./IconDoctor";
import IconFacility from "./IconFacility";
import IconHeartPlus from "./IconHeartPlus";
import IconNotFound from "./IconNotFound";
import IconClound from "./IconClound";
import IconSettingPartner from "./IconSettingPartner";
import IconUtilities from "./IconUtilities";
import IconMedia from "./IconMedia";
import IconRefresh from "./IconRefresh";
import IconTimer from "./IconTimer";
import IconDrafSpecialist from "./IconDrafSpecialist";
import IconMedalStar from "./IconMedalStar";
import IconFacilityOutline from "./IconFacilityOutline";
import IconLocalHospialOutline from "./IconLocalHospialOutline";
import IconFacilityBox from "./IconFacilityBox";
import IconCameraBox from "./IconCameraBox";
import IconSync from "./IconSync";
import IconCamera from "./IconCamera";
import IconCopy from "./IconCopy";
import IconRotateRight from "./IconRotateRight";
import { CheckOutlined } from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons";
import { UpOutlined } from "@ant-design/icons";
import IconArrowLeft from "./IconArrowLeft";
import { InfoCircleOutlined } from "@ant-design/icons";
import IconUserOutline from "./IconUserOutline";
import IconCall from "./IconCall";
import IconMedicalServices from "./IconMedicalServices";
import IconDollar from "./IconDollar";
import IconVideoFilled from "./IconVideoFilled";
import IconMenu from "./IconMenu";
import { CloseOutlined } from "@ant-design/icons";
import IconClockFilled from "./IconClockFilled";
import IconGiftFilled from "./IconGiftFilled";
import IconUserFilled from "./IconUserFilled";
import IconLock from "./IconLock";
import IconCheckCircle from "./IconCheckCircle";
import K31 from "./IconSpecialization/K31";
import K28 from "./IconSpecialization/K28";
import K27 from "./IconSpecialization/K27";
import K29 from "./IconSpecialization/K29";
import K19 from "./IconSpecialization/K19";
import K30 from "./IconSpecialization/K30";
import K13 from "./IconSpecialization/K13";
import K03 from "./IconSpecialization/K03";
import K08 from "./IconSpecialization/K08";
import BS_giadinh from "./IconSpecialization/BS_giadinh";
import No from "./IconSpecialization/No";
import K18 from "./IconSpecialization/K18";
import K39 from "./IconSpecialization/K39";
import K47 from "./IconSpecialization/K47";
import K16 from "./IconSpecialization/K16";
import K17 from "./IconSpecialization/K17";
import K17A from "./IconSpecializationActive/K17A";
import K16A from "./IconSpecializationActive/K16A";
import K47A from "./IconSpecializationActive/K47A";
import K39A from "./IconSpecializationActive/K39A";
import K18A from "./IconSpecializationActive/K18A";
import NoA from "./IconSpecializationActive/NoA";
import BS_giadinhA from "./IconSpecializationActive/BS_giadinhA";
import K08A from "./IconSpecializationActive/K08A";
import K03A from "./IconSpecializationActive/K03A";
import K13A from "./IconSpecializationActive/K13A";
import K30A from "./IconSpecializationActive/K30A";
import K19A from "./IconSpecializationActive/K19A";
import K29A from "./IconSpecializationActive/K29A";
import K27A from "./IconSpecializationActive/K27A";
import K28A from "./IconSpecializationActive/K28A";
import K31A from "./IconSpecializationActive/K31A";
import ApptDoctor from "./ServiceHome/ApptDoctor";
import ApptMedicine from "./ServiceHome/ApptMedicine";
import ApptOnline from "./ServiceHome/ApptOnline";
import ApptPackage from "./ServiceHome/ApptPackage";
import ApptSpecialization from "./ServiceHome/ApptSpecialization";
import IconDown from "./IconDown";
import IconMonitorHeart from "./IconMonitorHeart";
import IconNetWork from "./IconNetWork";
import IconPhone from "./IconPhone";
import IconEmail from "./IconEmail";
import IconLocationFooter from "./IconLocationFooter";
import IconMic from "./IconMic";
import IconVideo from "./IconVideo";
import IconVideoCall from "./IconVideoCall";
import IconVideoOff from "./IconVideoOff";
import IconMicOff from "./IconMicOff";
import IconFacebook from "./IconSocial/IconFacebook";
import IconX from "./IconSocial/IconX";
import IconMail from "./IconSocial/IconMail";
import IconPinterest from "./IconSocial/IconPinterest";
import IconLinkedin from "./IconSocial/IconLinkedin";

const CommonIcons = {
  IconCalendarFill: IconCalendarFill,
  IconHealthcare: IconHealthcare,
  IconHistory: IconHistory,
  IconHome: IconHome,
  IconLogout: IconLogout,
  IconMedication: IconMedication,
  IconProfile: IconProfile,
  IconTextSnippet: IconTextSnippet,
  IconVoucher: IconVoucher,
  IconSuccess: IconSuccess,
  IconInfo: IconInfo,
  IconWarning: IconWarning,
  IconError: IconError,
  IconArrowRight: IconArrowRight,
  IconRecord: IconRecord,
  SearchOutlined: SearchOutlined,
  IconMap: IconMap,
  IconLocation: IconLocation,
  PlusHome: PlusHome,
  RightOutlined: RightOutlined,
  LeftOutlined: LeftOutlined,
  IconRating: IconRating,
  IconGraduate: IconGraduate,
  IconHospital: IconHospital,
  IconSearchHome: IconSearchHome,
  IconContractHome: IconContractHome,
  IconOrderHome: IconOrderHome,
  IconExperienceHome: IconExperienceHome,
  IconArrowOrderHome: IconArrowOrderHome,
  IconApplication: IconApplication,
  IconHeart: IconHeart,
  IconBeautyHome: IconBeautyHome,
  IconTKHome: IconTKHome,
  IconMTHome: IconMTHome,
  IconVLTL: IconVLTL,
  IconKSK: IconKSK,
  IconK31: IconK31,
  IconHomeService: IconHomeService,
  IconPhone24h: IconPhone24h,
  IconLikeStart: IconLikeStart,
  IconCalenderPlus: IconCalenderPlus,
  IconPersonEdit: IconPersonEdit,
  ShareAltOutlined: ShareAltOutlined,
  HeartOutlineAntd: HeartOutlined,
  IconReply: IconReply,
  IconDoctor: IconDoctor,
  IconFacility: IconFacility,
  IconHeartPlus: IconHeartPlus,
  IconNotFound: IconNotFound,
  IconClound: IconClound,
  IconSettingPartner: IconSettingPartner,
  IconUtilities: IconUtilities,
  IconMedia: IconMedia,
  IconRefresh: IconRefresh,
  IconTimer: IconTimer,
  IconDrafSpecialist: IconDrafSpecialist,
  IconMedalStar: IconMedalStar,
  IconFacilityOutline: IconFacilityOutline,
  IconLocalHospialOutline: IconLocalHospialOutline,
  IconFacilityBox: IconFacilityBox,
  IconCameraBox: IconCameraBox,
  IconSync: IconSync,
  IconCamera: IconCamera,
  IconCopy: IconCopy,
  IconRotateRight: IconRotateRight,
  CheckOutlined: CheckOutlined,
  DownOutlined: DownOutlined,
  UpOutlined: UpOutlined,
  IconArrowLeft: IconArrowLeft,
  InfoCircleOutlined: InfoCircleOutlined,
  IconUserOutline: IconUserOutline,
  IconCall: IconCall,
  IconMedicalServices: IconMedicalServices,
  IconDollar: IconDollar,
  IconVideoFilled: IconVideoFilled,
  IconMenu: IconMenu,
  CloseOutlined: CloseOutlined,
  IconClockFilled: IconClockFilled,
  IconGiftFilled: IconGiftFilled,
  IconUserFilled: IconUserFilled,
  IconLock: IconLock,
  IconCheckCircle: IconCheckCircle,
  K31: K31,
  K28: K28,
  K27: K27,
  K29: K29,
  K19: K19,
  K30: K30,
  K13: K13,
  K03: K03,
  K08: K08,
  BS_giadinh: BS_giadinh,
  No: No,
  K18: K18,
  K39: K39,
  K47: K47,
  K16: K16,
  K17: K17,

  K31A: K31A,
  K28A: K28A,
  K27A: K27A,
  K29A: K29A,
  K19A: K19A,
  K30A: K30A,
  K13A: K13A,
  K03A: K03A,
  K08A: K08A,
  BS_giadinhA: BS_giadinhA,
  NoA: NoA,
  K18A: K18A,
  K39A: K39A,
  K47A: K47A,
  K16A: K16A,
  K17A: K17A,

  ApptDoctor: ApptDoctor,
  ApptMedicine: ApptMedicine,
  ApptOnline: ApptOnline,
  ApptPackage: ApptPackage,
  ApptSpecialization: ApptSpecialization,
  IconDown: IconDown,
  IconMonitorHeart: IconMonitorHeart,
  IconNetWork: IconNetWork,
  IconPhone: IconPhone,
  IconEmail: IconEmail,
  IconLocationFooter: IconLocationFooter,
  IconMic: IconMic,
  IconVideo: IconVideo,
  IconVideoCall: IconVideoCall,
  IconVideoOff: IconVideoOff,
  IconMicOff: IconMicOff,

  IconFacebook: IconFacebook,
  IconX: IconX,
  IconMail: IconMail,
  IconPinterest: IconPinterest,
  IconLinkedin: IconLinkedin,
};

export default CommonIcons;
